jQuery(document).ready(function($) {
    
   //phone mask
   $('.phone-mask').mask('+375 (00) 000-00-00');
   $('.phone-mask').click(function() {
       if($(this).val() == '') {
           $(this).val('+375 (')
       }
   });

   $('.catalog__item-slider').slick({
        lazyLoad: 'ondemand',
       dots: true,
       arrows: true
   });

   $('.reviews__slider').slick({
        lazyLoad: 'ondemand',
        asNavFor: '.reviews__slider-content',
        prevArrow: $('.reviews__slider-prev'),
        nextArrow: $('.reviews__slider-next'),
        fade: true,
        cssEase: 'linear'
   });

   $('.reviews__slider-content').slick({
        lazyLoad: 'ondemand',
        asNavFor: '.reviews__slider',
        dots: true,
        arrows: false,
        fade: true,
        cssEase: 'linear'
    });

    $('.photo__slider').slick({
        dots: true,
        arrows: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: $('.photo__slider-prev'),
        nextArrow: $('.photo__slider-next'),
        responsive: [
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    });

    $('.advantages__slider').slick({
        lazyLoad: 'ondemand',
        dots: true,
        arrows: true
    });

    $("#cookieButton").click(function(){
        document.cookie="cookiepolicy=true; max-age=15780000";
        btn.classList.add("disabled");
    });

    // логика квиза
    $('.quiz__answers li').click(function() {
        if($(this).parent().hasClass('oneOfAll')) { // если есть класс - можно выбрать один вариант ответа
            $(this).siblings().removeClass('active');
            $(this).addClass('active');
            $('#quizLength').val($(this).text());
            // console.log($(this).text());
        }
    });

    $('.quiz__answers li').click(function() {
        if($(this).parent().hasClass('multiple')) { // если есть класс - можно выбрать несколько вариантов ответа
            $(this).toggleClass('active');
            var dataInput = $(this).data('input'); // вписываем значение в поле, если ставится галочка
            if($("input[name=" + dataInput + "]").val() != $(this).text()) { // если значение поля уже такое же, как значение варианта - ставим нет
                $("input[name=" + dataInput + "]").val($(this).text());
            } else {
                $("input[name=" + dataInput + "]").val('Нет');
            }
        }
    });

    $('.quiz__next').click(function() {// логика клика на кнопку следующего шага на первом экране
        if($('.quiz__question--first').hasClass('active')) {
            if(!$('.quiz__question--first .quiz__answers li').hasClass('active')) {
                swal ( "Ошибка" ,  "Выберите вариант ответа" ,  "error" )
            } else {
                $('.quiz__question--first').fadeOut();
                setTimeout(function() {
                    $('.quiz__question--first').removeClass('active');
                    $('.quiz__question--second').fadeIn();
                    $('.quiz__question--second').addClass('active');
                    $('.discount__value .value').html('30<span>₽</span>');
                    $('.quiz__current .current').removeClass('current');
                    $('#quiz__step-second').addClass('current');
                }, 500);
            }
        } else if($('.quiz__question--second').hasClass('active')) {// логика клика на кнопку следующего шага на втором экране
            if(!$('.quiz__question--second .quiz__answers li').hasClass('active')) {
                swal ( "Ошибка" ,  "Выберите вариант ответа" ,  "error" )
            } else {
                $('.quiz__question--second').fadeOut();
                setTimeout(function() {
                    $('.quiz__question--second').removeClass('active');
                    $('.quiz__question--third').fadeIn();
                    $('.quiz__question--third').addClass('active');
                    $('.discount__value .value').html('40<span>₽</span>');
                }, 500);
            }
        } else if($('.quiz__question--third').hasClass('active')) {// логика клика на кнопку следующего шага на третьем экране
            if(!$('.quiz__question--third .quiz__answers li').hasClass('active')) {
                swal ( "Ошибка" ,  "Выберите вариант ответа" ,  "error" )
            } else {
                $('.quiz__question--third').fadeOut();
                $('.quiz__description').fadeOut();
                $('.quiz__next').fadeOut();
                setTimeout(function() {
                    $('.quiz__question--third').removeClass('active');
                    $('.quiz__question--fourth').fadeIn();
                    $('.quiz__question--fourth').addClass('active');
                    $('.discount__value .value').html('50<span>₽</span>');
                    $('.quiz__current .current').removeClass('current');
                    $('#quiz__step-third').addClass('current');
                }, 500);
            }
        }
    }); 

    //плавный скролл по ссылкам
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
        });
    });

    function hideOverflow() {
        $('html').addClass('active');
        $('body').addClass('active');
    }

    function initialOverflow() {
        $('html').removeClass('active');
        $('body').removeClass('active');
    }

    //клик по оверлею закрывает модалку
    $('.modal--overlay').click(function(e) {
        var clicked = $(e.target);
        if (clicked.is('.modal') || clicked.parents().is('.modal')) {
        } else {
          $('.modal--overlay').fadeOut();
          initialOverflow();
        }
    });

    $('.modal__close').click(function() {
        if(!$(this).next().hasClass('tg')) {
            $(this).parent().parent().fadeOut();
            initialOverflow();
        } else {
            $(this).parent().parent().parent().parent().fadeOut();
            initialOverflow();
        }
    });

    $('.btnCallModal').click(function() {
        $('.modal--overlay.callModal').fadeIn();
        hideOverflow();
    });

    $('.what').click(function() {
        $('.modal--overlay.whatModal').fadeIn();
        hideOverflow();
        $('.whatModal__title').text($(this).data('title'));
        // console.log($(this).data('title'));
        $('.whatModal__text').text($(this).data('text'));
        $('.whatModal__image').attr('src', ($(this).data('image')));
    });

    $('.length li').click(function() {
        $(this).siblings().removeClass('active');
        $(this).addClass('active');
        $(this).parent().parent().next().val($(this).text());
    });

    //фиксированное меню
    // $(window).scroll(function () {
    //     if ($(window).width() > 992) {
    //         var toTop = $(".header__menu--overlay").offset().top - $(document).scrollTop();
    //         // console.log(toTop);
    //         if(toTop <= 0) {
    //             $(".header__menu--overlay").addClass('fixed');
    //             // $('body').addClass('menu-active');
    //         } 
    //         if ($(".header").offset().top - $(document).scrollTop() == 0) {
    //             $(".header__menu--overlay").removeClass('fixed');
    //             // $('body').removeClass('menu-active');
    //         }
    //     }
    // });

    $('#menu-btn').click(function() {
        $(this).toggleClass('active');
        $('.header__menu--overlay').fadeToggle();
        $('.header__menu--overlay').toggleClass('active');
        $('body').toggleClass('active');
        $('html').toggleClass('active');
    });

    $('.header__menu--overlay a').click(function() {
        if ($(window).width() < 993) {
            $('.header__menu--overlay').fadeOut();
            $('.header__menu--overlay').removeClass('active');
            $('body').removeClass('active');
            $('html').removeClass('active');
            $('#menu-btn').removeClass('active');
        }
    });

    // фикс проблемы с неотображаемыми фото в слик-слайдере после просмотра в фенсибоксе
    $('[data-fancybox]').fancybox({
        backFocus : false,
    });
    

});

